import "../App.css";
import Header from "../components/maincomponents/Header";
import Body from "../components/maincomponents/Body/Body";
import Footer from "../components/maincomponents/Footer";

import en from "../assets/languages/web-en.json"; // İngilizce dil dosyası
import id from "../assets/languages/web-id.json"; // Endonezyaca dil dosyası
import ph from "../assets/languages/web-ph.json"; // Filipin dil dosyası
import LanguageSelector from "../components/maincomponents/LanguageSelector";
import { useState } from "react";

function MainPage() {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [texts, setTexts] = useState(en);

  const languages = [
    {
      code: "en",
      name: "English",
      flag: "https://imagedelivery.net/iUl9s7edQj20HFN2aldT1g/c1002972-8ea1-4711-5894-ea495c6ea400/public",
    },
    {
      code: "id",
      name: "Bahasa",
      flag: "https://imagedelivery.net/iUl9s7edQj20HFN2aldT1g/d2351d2d-53fb-4642-bf62-7558e95a2500/public",
    },
    {
      code: "ph",
      name: "Filipino",
      flag: "https://imagedelivery.net/iUl9s7edQj20HFN2aldT1g/fb45fbb8-c9d2-45e1-de85-e511c31cca00/public",
    },
    {
      code: "de",
      name: "Deutsch",
      flag: "https://imagedelivery.net/iUl9s7edQj20HFN2aldT1g/9cf21c6b-f627-40b1-eb28-95573eef0f00/public",
    },
    {
      code: "fr",
      name: "Français",
      flag: "https://imagedelivery.net/iUl9s7edQj20HFN2aldT1g/c9ae9b95-0d56-4295-3f93-2be44d845400/public",
    },
    {
      code: "it",
      name: "Italiano",
      flag: "https://imagedelivery.net/iUl9s7edQj20HFN2aldT1g/d5137b00-f975-40f9-328f-ba52b7b22600/public",
    },
  ];

  const handleLanguageChange = (langCode) => {
    //console.log(`Switching to ${langCode}...`);
    //setCurrentLanguage(langCode);
    localStorage.setItem("lang", langCode);

    if (langCode === "en") {
      setTexts(en);
    } else if (langCode === "id") {
      setTexts(id);
    } else if (langCode === "ph") {
      setTexts(ph);
    } else {
      setTexts(en);
    }
  };

  return (
    <div className="container">
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "50px",
          textAlign: "end",
        }}
      >
        <LanguageSelector
          languages={languages}
          currentLanguage={currentLanguage}
          onChangeLanguage={handleLanguageChange}
        />
      </div>
      <Header texts={texts} />

      <Body texts={texts} />

      <Footer texts={texts} />
    </div>
  );
}

export default MainPage;
