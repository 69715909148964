import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./InviteFriend.css";
import { ContentCopy, CheckCircle } from "@mui/icons-material";

import iosIcon from "../../assets/images/iosIcon.png";
import playIcon from "../../assets/images/playIcon.png";
import logo from "../../assets/images/logo.png";

function InviteFriendUK() {
  const codeRef = useRef(null);
  const { code } = useParams();
  const [isCopied, setIsCopied] = useState(false);

  const copyCode = () => {
    if (codeRef.current) {
      codeRef.current.select();
      document.execCommand("copy");
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500); // Reset the copied state after 1.5 seconds
    }
  };

  return (
    <div className="invite-code-container">
      <div className="invite-code-container-box">
        <div
          style={{
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt="main1" className="logo" />
        </div>
        <h1 style={{ fontStyle: "italic" }}>StepMate</h1>
        <div className="invite-code-text">
          Your friend invites you to use StepMate. After downloading the
          application, sign up using the invitation code and earn the gift
          StepMate points.
        </div>
        <div className="invite-code-box">
          <div className="invite-code-inner-box">
            <div>
              <div className="invite-code-title-text"> Invite Code </div>
              <div
                style={{
                  flex: "1",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  border: "0px solid",
                  borderRadius: "4px",
                }}
              >
              {code ? (
                <input
                  type="text"
                  value={code}
                  ref={codeRef}
                  readOnly
                  className="invite-code-input"
                />
              ) : (
                <p>Invite code not found.</p>
              )}
              </div>
            </div>
            <div style={{ flex: "1", marginTop: "3px", marginLeft: "-20px" }}>
              <button className="copy-icon" onClick={copyCode}>
                {isCopied ? <CheckCircle /> : <ContentCopy />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "60px" }}>
        <a href="https://apps.apple.com/gb/app/stepmate/id6476861019">
          <img src={iosIcon} alt="main1" className="appleStoreIcon" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.pati.stepmate.app">
          <img src={playIcon} alt="main1" className="playStoreIcon" />
        </a>
      </div>
    </div>
  );
}

export default InviteFriendUK;
