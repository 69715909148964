import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CharityShare.css'; // Include a CSS file for styling
import iosIcon from "../../assets/images/iosIcon.png";
import playIcon from "../../assets/images/playIcon.png";
import logo from "../../assets/images/logo.png";
import { useParams } from 'react-router-dom';

const CharityShare = () => {
  const { code } = useParams();
  const [charityInfo, setCharityInfo] = useState({
    charityShareTitle: '',
    charityShareMessage: '',
    charitySharePageImage: '',
    charityShareCode: '',
  });

  const [showText, setShowText] = useState(true); // State to show/hide text
  const [showLogos, setShowLogos] = useState(false); // State to show/hide logos
  const [fadeOut, setFadeOut] = useState(false); // State to trigger fade-out animation

  useEffect(() => {
    const fetchCharityShareInfo = async () => {
      try {
        const response = await axios.get(`https://apiuk.stepmate.app/products/charityShareInfo`, {
          params: { code },
        });
        console.log(code)
        console.log(response.data)
        setCharityInfo(response.data);
      } catch (error) {
        console.error('Error fetching charity share info:', error);
      }
    };

    if (code) {
      fetchCharityShareInfo();
    }
  }, [code]);

  const handleAcceptInvite = () => {
    // Trigger fade-out animation
    setFadeOut(true);
    setTimeout(() => {
      setShowText(false);
      setShowLogos(true);
    }, 400);
  };

  return (
    <div
      className="charity-share-container"
      style={{ backgroundImage: `url(${charityInfo.charitySharePageImage})` }}
    >
      <div className="charity-share-body">
        {showText && (
          <div className={`charity-share-content ${fadeOut ? 'fade-out' : ''}`}>
            <button className="charity-accept-invite-button" onClick={handleAcceptInvite}>
              Accept Invite
            </button>
            <h1>{charityInfo.charityShareTitle}</h1>
            <p>{charityInfo.charityShareMessage}</p>
          </div>
        )}

        {showLogos && (
          <div className={`fade-in`}>
            <div className="charity-inner-store-logos">
              <a href="https://apps.apple.com/id/app/stepmate/id6476861019">
                <img src={iosIcon} alt="iOS App" className="charity-appleStoreIcon" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.pati.stepmate.app">
                <img src={playIcon} alt="Google Play App" className="charity-playStoreIcon" />
              </a>
            </div>

            <div className="charity-logo-container">
              <img src={logo} alt="Logo" className="logo" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CharityShare;
