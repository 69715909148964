import React from "react";
import { Grid } from "@mui/material";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";

const PrivacyId = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  return (
    <div
      className="div1"
      style={{ color: "white", paddingTop: isMobile ? "70px" : "5px" }}
    >
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>
            <br></br>
            <u>
              {" "}
              <strong>
                <p>KEBIJAKAN PERLINDUNGAN DATA PRIBADI DAN PRIVASI</p>
              </strong>
            </u>
            <br></br>
          </center>

          <p>
            Penggunaan dan transfer informasi yang diterima dari Google APIs
            oleh Step Mate akan tunduk pada
            <a href="https://developers.google.com/terms/api-services-user-data-policy">
              Kebijakan Data Pengguna Layanan API Google
            </a>
            , termasuk persyaratan Penggunaan Terbatas.
          </p>
          <p>
            Kebijakan Perlindungan Data Pribadi dan Privasi ini (selanjutnya
            disebut "Kebijakan Privasi") tanggal Maret 2023. Undang-Undang No.
            6698 dan Peraturan Perundang-undangan Lainnya. Dalam hal ada
            perubahan, hal ini akan diberitahukan secara terpisah.
          </p>
          <br></br>
          <ol style={{ color: "white" }}>
            <li>
              <strong>1. Tujuan</strong>
            </li>
          </ol>
          <p>
            Kebijakan ini menjelaskan data pribadi yang dikumpulkan oleh Pati
            Teknoloji Dış Ticaret A.Ş. melalui aplikasi seluler bernama Step
            Mate, dengan siapa data ini dapat dibagikan, bagaimana cara
            dibagikan, bagaimana cara digunakan, hak-hak Anda dan metode untuk
            melaksanakan hak-hak Anda, serta cara Anda dapat memilih untuk
            menerima atau tidak menerima pesan komersial elektronik.
          </p>
          <br></br>
          <ol start="2" style={{ color: "white" }}>
            <li>
              <strong>
                {" "}
                2. Pemrosesan Data Pribadi Anda dan Tujuan serta Dasar Hukum
                untuk Pemrosesan
              </strong>
            </li>
          </ol>
          <p>
            Informasi pribadi Anda (nama, nama belakang, nomor ID, alamat email,
            alamat rumah, usia dan jenis kelamin, status pendidikan, dll.) dapat
            diminta atau telah diminta dari Anda melalui aplikasi Step Mate dan
            situs web untuk memberikan layanan berkualitas lebih baik kepada
            pengguna aplikasi, dalam batas-batas yang ditetapkan oleh peraturan
            dan kewajiban yang diimpos oleh peraturan. Di bawah langkah-langkah
            keamanan yang diperlukan, informasi tersebut akan digunakan,
            diproses, dan disimpan untuk tujuan yang dijelaskan di sini
            berdasarkan persetujuan tegas Anda
          </p>
          <br></br>
          <ol start="3" style={{ color: "white" }}>
            <li>
              <strong>3. Tentang Data Pribadi Anda yang Diproses</strong>
            </li>
          </ol>
          <p>
            Jika Anda menggunakan aplikasi Step Mate, mendaftar dan/atau
            berpartisipasi dalam acara, kami mengumpulkan informasi Anda untuk
            tujuan yang dijelaskan di sini, termasuk tetapi tidak terbatas pada
            menyediakan kampanye umum dan personal, hadiah, keuntungan, dll.,
            menyelesaikan masalah yang mungkin Anda alami karena aplikasi atau
            alasan lainnya. Kami memproses informasi anggota kami, pengunjung,
            peserta di bagian yang terkait dengan nama, nama belakang, dan
            informasi pribadi yang diisi dalam formulir yang relevan, lokasi,
            dan alamat IP di jaringan internet, bidang terkait, dll., informasi
            Anda, preferensi Anda, baik untuk melakukan studi statistik umum
            maupun untuk menyusun kampanye umum atau khusus, keuntungan, hadiah.
            Dalam konteks ini, data Anda (termasuk informasi langkah Anda)
            dikumpulkan secara digital, langsung atau tidak langsung, dan
            disimpan selama yang diperlukan dalam batas hukum dan di bawah
            langkah-langkah keamanan siber.
          </p>
          <p>
            Informasi pribadi Anda (nama, nama belakang, nomor ID, alamat email,
            alamat rumah, usia dan jenis kelamin, status pendidikan, dll.) dapat
            diminta atau telah diminta dari Anda melalui aplikasi Step Mate dan
            situs web untuk memberikan layanan berkualitas lebih baik kepada
            pengguna aplikasi, dalam batas-batas yang ditetapkan oleh peraturan
            dan kewajiban yang diimpos oleh peraturan. Di bawah langkah-langkah
            keamanan yang diperlukan, informasi tersebut akan digunakan,
            diproses, dan disimpan untuk tujuan yang dijelaskan di sini
            berdasarkan persetujuan tegas Anda.
          </p>
          <br></br>
          <ol start="4" style={{ color: "white" }}>
            <li>
              <strong>4. Kewenangan Anda Terkait Data Pribadi</strong>
            </li>
          </ol>
          <p>
            Anda dapat menghubungi kami di contact@stepmate.app untuk mengetahui
            tujuan pengolahan data pribadi Anda, jenis data yang sedang
            diproses, apakah data Anda sedang ditransfer kepada pihak ketiga di
            dalam negeri atau internasional, dan untuk meminta koreksi,
            pembaruan, atau penghapusan data pribadi yang tidak lengkap atau
            salah. Jika Anda ingin menghapus akun secara permanen, Anda dapat
            melakukannya dari profil Anda (Buka aplikasi Step Mate! {"->"} Akun{" "}
            {"->"}
            Profil {"->"} Edit Profil {"->"} Hapus Akun). Informasi yang tidak
            perlu yang terkait dengan akun yang dihapus akan dihapus,
            dihancurkan, atau diubah menjadi anonim dalam waktu 30 hari sejak
            tanggal penghapusan, kecuali untuk data yang perlu disimpan secara
            hukum. Selain itu, Anda dapat menggunakan hak-hak lain Anda
            berdasarkan Pasal 11 Undang-Undang Perlindungan Data Pribadi No.
            6698
          </p>
          <br></br>
          <ol start="5" style={{ color: "white" }}>
            <li>
              <strong>5. Transfer Data Pribadi Anda ke Pihak Ketiga</strong>
            </li>
          </ol>
          <p>
            Data pribadi Anda dapat dibagikan dengan segala jenis penyedia
            layanan, pemasok, mitra bisnis Perusahaan kami, dan lembaga terkait
            serta pihak ketiga dalam hal persyaratan hukum. Ini mungkin juga
            termasuk yang berlokasi di luar negeri. Berbagi di sini dibatasi
            sebanding dengan tujuannya, bertujuan untuk meningkatkan kualitas
            layanan yang diberikan dan memastikan pemenuhan layanan secara
            langsung. Selain itu, data Anda yang harus dibagikan sesuai dengan
            peraturan hukum akan dibagikan dengan lembaga-lembaga terkait.
          </p>
          <br></br>
          <ol start="6" style={{ color: "white" }}>
            <li>
              <strong>
                6. Hak-hak Anda berdasarkan Pasal 11 Undang-Undang No. 6698
              </strong>
            </li>
          </ol>
          <p>
            Berdasarkan Pasal 11 Undang-Undang No. 6698; Setiap orang, dengan
            mengajukan permohonan kepada pengendali data;
            <ul>
              <li>a) Mengetahui apakah data pribadi diproses,</li>
              <li>b) Meminta informasi jika data pribadi telah diproses,</li>
              <li>
                c) Mengetahui tujuan pemrosesan data pribadi dan apakah
                digunakan sesuai dengan tujuannya,
              </li>
              <li>
                d) Mengetahui pihak ketiga yang menerima transfer data pribadi
                di dalam negeri atau ke luar negeri,
              </li>
              <li>
                e) Meminta koreksi data pribadi dalam hal pemrosesan yang tidak
                lengkap atau salah,
              </li>
              <li>
                f) Meminta penghapusan atau penghancuran data pribadi dalam
                kerangka kondisi yang ditentukan dalam Pasal 7,
              </li>
              <li>
                g) Meminta pemberitahuan atas transaksi yang dilakukan sesuai
                dengan subpasal (e) dan (f) kepada pihak ketiga yang menerima
                transfer data pribadi,
              </li>
              <li>
                h) Melawan munculnya hasil merugikan bagi dirinya sendiri dengan
                menganalisis data yang diproses secara eksklusif melalui sistem
                otomatis,
              </li>
              <li>
                i) Dalam hal kerugian akibat pemrosesan data pribadi yang
                melanggar hukum, berhak menuntut ganti kerugian".
              </li>
            </ul>
          </p>
          <p>
            Apabila alasan yang memerlukan pemrosesan tersebut menghilang, data
            pribadi Anda akan dihapus, dihancurkan, atau dianonimkan oleh
            pengendali data secara ex officio atau atas permintaan orang yang
            bersangkutan. Anda dapat mengirimkan semua permintaan, pertanyaan,
            dan komentar Anda, jika ada, dalam cakupan ini ke
            contact@stepmate.app
          </p>
          <p>
            Namun, dengan ini kami informasikan bahwa Pati Teknoloji Dış Ticaret
            A.Ş. tidak bertanggung jawab atas segala kerugian yang timbul secara
            langsung atau tidak langsung dari aplikasi dan layanan yang
            diberikan.
          </p>
          <br></br>
          <ol start="7" style={{ color: "white" }}>
            <li>
              <strong>7. Persetujuan Tegas </strong>
            </li>
          </ol>

          <p>
            Dengan menerima Kebijakan Perlindungan Data Pribadi dan Privasi ini;
            Anda akan memberikan persetujuan tegas Anda untuk dikumpulkan dan
            disimpan untuk tujuan yang tercantum di atas, untuk dibagikan secara
            domestik dan internasional, untuk meningkatkan dan mempersonalisasi
            layanan yang ditawarkan, untuk digunakan untuk tujuan komersial dan
            membuat pemberitahuan melalui pesan teks, surat, pesan suara, dan
            metode lainnya serta dibagikan dengan pihak ketiga.
          </p>
          <br></br>
          <ol start="8" style={{ color: "white" }}>
            <li>
              <strong>8. Pengadilan yang Berkompeten </strong>
            </li>
          </ol>
          <p>
            Kebijakan Privasi ini telah disusun sesuai dengan ketentuan
            Undang-Undang No. 6698 dan peraturan perundang-undangan lainnya.
            Dalam hal sengketa, hukum Republik Turki akan berlaku. Pengadilan
            yang berkompeten adalah Pengadilan dan Kantor Eksekusi Pusat
            Istanbul.
          </p>

          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default PrivacyId;
