import React from "react";
import { Grid } from "@mui/material";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";

const CookiePolicyId = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  return (
    <div className="div1" style={{ color: "white" }}>
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>

            <br></br>
            <u>
              <p>
                <strong>KEBIJAKAN COOKIE</strong>
              </p>
            </u>
            <br></br>
          </center>

          <p>
            Kami menggunakan cookie agar Anda dapat menggunakan situs web kami
            (www.stepmate.app) dengan efisien dan meningkatkan pengalaman
            pengguna Anda.
          </p>
          <br />
          <p>
            Saat Anda masuk ke situs web kami, Anda akan diminta pertanyaan
            tentang apakah Anda menerima penggunaan Cookie. Cookie akan aktif
            setelah Anda memilih opsi 'Terima'. Jika Anda tidak ingin
            menggunakan cookie, Anda selalu dapat menghapus/memblokir cookie di
            pengaturan browser Anda. Namun, memblokir cookie dapat memengaruhi
            penggunaan situs. Penggunaan cookie tidak dibatasi oleh periode
            waktu tertentu.
          </p>
          <br />
          <p>
            Anda membagikan beberapa informasi dan data kepada kami secara
            langsung atau tidak langsung untuk penggunaan konten di situs web
            kami. Ini adalah informasi pribadi yang Anda berikan saat mendaftar
            (seperti nama, alamat email, usia, jenis kelamin, telepon) atau
            informasi yang kami kumpulkan selama penggunaan situs (penggunaan
            formulir kontak, komentar, dll.) (data seperti informasi IP Anda,
            perangkat, lokasi, dan informasi browser yang digunakan, konten
            navigasi dan interaksi, tanggal, dan waktu koneksi). Kami berusaha
            untuk menyimpan informasi dan data Anda. Situs web kami tidak
            membagikan data Cookie Anda yang disimpan di server web kami dengan
            pihak ketiga. Data Cookie Anda yang disimpan oleh layanan eksternal
            (plugin Live Support, plugin Facebook, video YouTube, konten
            Twitter, dll.) menjadi tanggung jawab layanan eksternal tersebut.
          </p>
          <br />
          <p>
            <strong>Apa itu Cookie</strong>
          </p>
          <p>
            Situs web dapat menyimpan informasi di tablet, komputer, ponsel, dan
            perangkat lain yang Anda gunakan, termasuk tetapi tidak terbatas
            pada tablet, dan menggunakan informasi ini saat Anda mengunjungi
            situs web yang sama. File di mana informasi kecil ini disimpan
            disebut cookie. Cookie digunakan untuk meningkatkan pengalaman
            penjelajahan Anda dan mempersonalisasi situs web. Sebagai contoh,
            jika Anda memilih opsi "ingat saya" saat masuk ke situs web, situs
            tersebut menyimpan cookie yang berisi nama pengguna Anda (dll.) di
            browser Anda. Cookie hanya dapat dibaca oleh situs web yang
            membuatnya. Situs web menentukan periode penyimpanan.
          </p>
          <br />
          <p>Jenis data apa yang diolah dalam cookie?</p>
          <p>
            Cookie di situs web, tergantung pada jenis pengumpulan data,
            mengumpulkan data tentang preferensi pengguna Anda pada perangkat
            yang Anda gunakan untuk mengunjungi situs. Data ini mencakup
            informasi tentang halaman yang Anda kunjungi, layanan dan produk
            yang Anda lihat, browser pilihan Anda, opsi bahasa, dan preferensi
            lainnya.
          </p>
          <br />
          <p>
            <strong>Mengapa Cookie Digunakan?</strong>
          </p>
          <p>
            Untuk meningkatkan layanan yang ditawarkan kepada Anda agar situs
            web lebih berguna dan meningkatkan kinerjanya,
          </p>
          <p>
            Meningkatkan situs web dengan mempersonalisasi sesuai dengan
            preferensi Anda, menyiapkan fitur tambahan
          </p>
          <p>
            Memastikan keamanan komersial dan hukum Situs web dan perusahaan
            kami, mencegah tautan palsu
          </p>
          <p>
            Memenuhi persyaratan perundang-undangan (khususnya; Undang-Undang
            No. 5651 tentang Pengaturan Publikasi di Internet dan Pemberantasan
            Kejahatan yang Dilakukan melalui Publikasi Ini dan Peraturan tentang
            Prosedur dan Prinsip-prinsip Pengaturan Publikasi di Internet, dll.)
          </p>
          <br />
          <p>
            <strong>JENIS-JENIS COOKIE</strong>
          </p>
          <br />
          <p>
            <strong>Cookie Sesi</strong>
          </p>
          <p>
            Cookie sementara (non-permanen) adalah cookie yang aktif selama
            sesi, dihapus pada akhir sesi, dan disiapkan untuk operasi web yang
            benar dan dapat diandalkan.
          </p>
          <br />
          <p>
            <strong>Cookie Persisten</strong>
          </p>
          <p>
            Cookie persisten yang digunakan untuk mengingat Anda saat Anda
            mengunjungi situs web selanjutnya. Mereka disimpan di browser Anda
            hingga Anda menghapusnya dari browser Anda.
          </p>
          <br />
          <p>
            <strong>Cookie Kinerja</strong>
          </p>
          <p>
            Digunakan untuk meningkatkan kinerja situs web dan kenyamanan
            penggunaan.
          </p>
          <br />
          <p>
            <strong>Cookie Fungsional</strong>
          </p>
          <p>
            Cookie yang mengingatkan pengunjung tentang pilihan yang dibuat
            sebelumnya untuk kenyamanan pengunjung.
          </p>
          <br />
          <p>
            <strong>Cookie Analitis</strong>
          </p>
          <p>
            Cookie ini digunakan untuk mengumpulkan dan menganalisis seberapa
            sering halaman digunakan, kapan mereka digunakan, dan data tentang
            pengguna.
          </p>
          <br />
          <p>
            <strong>Cookie Periklanan dan Pihak Ketiga</strong>
          </p>
          <p>
            Cookie ini adalah cookie milik pemasok pihak ketiga dan, jika
            digunakan, memungkinkan penggunaan beberapa konten di situs web dan
            pelacakan iklan (menampilkan iklan yang sesuai untuk pengguna,
            menarik bagi pengguna)
          </p>
          <p>
            Cookie yang digunakan oleh situs web kami selain yang wajib dan
            pihak pertama adalah sebagai berikut:
          </p>
          <br />
          <p>
            <strong>Google Analytics</strong>
          </p>
          <p>
            Ini adalah alat statistik yang disediakan oleh Google. Ini
            memungkinkan administrator situs web untuk menganalisis kit
            pengguna. Mengumpulkan informasi pribadi seperti nama depan, nama
            belakang, nomor telepon, alamat IP, dll. bersamaan dengan informasi
            anonim umum dan mengirimkannya ke Google untuk dicatat.
          </p>
          <br />
          <p>
            <strong>Dapatkah Penggunaan Cookie Diblokir?</strong>
          </p>
          <p>
            Anda dapat menghapus/menstop cookie, yang diterima secara otomatis
            oleh sebagian besar browser Anda atau dengan persetujuan Anda,
            melalui pengaturan browser Anda. Namun, karena banyak cookie,
            seperti yang dijelaskan di atas, berfungsi untuk fungsi yang benar
            dari situs web, penghapusan cookie dapat memengaruhi operasi situs.
            Berikut informasi tentang langkah-langkah yang harus diikuti untuk
            mencegah penggunaan cookie melalui beberapa browser internet. Karena
            petunjuk dapat bervariasi, Anda dapat menemukan langkah-langkah saat
            ini di menu bantuan browser masing-masing.
          </p>

          <br />

          <p>
            <strong>- Internet Explorer</strong>
          </p>
          <p>
            Buka desktop komputer Anda dan ketuk atau klik ikon "Internet
            Explorer" di bilah tugas.
          </p>
          <p>Klik tombol "Tools", kemudian klik "Internet options".</p>
          <p>
            - Ketuk atau klik tab "Privacy", lalu geser slider di bawah
            pengaturan ke atas untuk memblokir semua cookie dan klik "OK".
          </p>
          <br />
          <p>
            <strong>- Microsoft Edge</strong>
          </p>
          <p>
            Di sudut kanan atas browser "Microsoft Edge" Anda, klik pada bagian
            dengan tiga titik dan pergi ke bagian "Settings".
          </p>
          <p>
            Pada jendela baru yang muncul, pilih "Select Items to Clear", lalu
            pilih cookie yang ingin Anda hapus dari jendela yang muncul.
          </p>
          <p>
            - Ada banyak bagian di sini. Anda dapat memulai proses pembersihan
            dengan memilih cookie yang Anda inginkan.
          </p>

          <br />

          <p>
            <strong>- Google Chrome</strong>
          </p>
          <p>- Buka Google Chrome di komputer Anda.</p>
          <p>- Klik "More Settings"</p>
          <p>- di bagian kanan atas.</p>
          <p>- Klik "Advanced" di bagian bawah.</p>
          <p>- Di bawah "Privacy and Security", klik "Content Settings".</p>
          <p>- Kemudian klik "Cookies".</p>
          <p>
            - Di bawah "All cookies and Site Data", cari nama situs web yang
            Anda masuki. Klik ikon "Remove" di sebelah kanan situs.
          </p>

          <br />

          <p>
            <strong>- Mozilla Firefox</strong>
          </p>
          <p>
            - Dari browser "Firefox" Anda, klik tombol "Menu" dan pergi ke
            "Options".
          </p>
          <p>
            - Pilih panel "Privacy and Security" dan pergi ke bagian "History".
          </p>
          <p>
            - Ubah pengaturan Firefox menjadi "use custom settings for history".
          </p>
          <p>- Klik tombol "Show cookies". Jendela cookie akan muncul.</p>
          <p>
            - Di bidang pencarian, ketik nama situs web yang cookie-nya ingin
            Anda hapus. Cookie yang sesuai dengan pencarian Anda dengan situs
            web akan ditampilkan.
          </p>
          <p>
            - Pilih cookie yang ingin Anda hapus dan klik tombol "Delete
            Selected".
          </p>
          <p>
            - Tutup jendela cookie dengan mengklik tombol "Close". Kemudian
            tutup halaman "about:preferences".
          </p>

          <br />

          <p>
            <strong>- Safari</strong>
          </p>
          <p>- Masuk ke browser "Safari" dan pergi ke "Preferences".</p>
          <p>- Klik "Privacy".</p>
          <p>- Klik "Website Data".</p>
          <p>
            - Pilih satu atau beberapa situs web, lalu klik "Delete or Delete
            All".
          </p>
          <p>- Hubungi Kami</p>

          <br />
          <p>
            <strong>Contact us</strong>
          </p>
          <p>
            Anda dapat mengirimkan email ke contact@stepmate.app untuk
            mengirimkan semua pertanyaan dan komentar Anda
          </p>
          <br />
          <p>
            <strong>Kebijakan Retensi dan Penghancuran Data</strong>
          </p>
          <p>
            Anda dapat mengirimkan email ke contact@stepmate.app untuk
            penghancuran data pribadi yang disimpan Anda.
          </p>

          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default CookiePolicyId;
