import React from "react";
import { Grid } from "@mui/material";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";

const FaqInd = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  return (
    <div className="div1" style={{ color: "white" }}>
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>
            <br></br>

            <u>
              <p>
                <strong>Pertanyaan yang Sering Diajukan</strong>
              </p>
            </u>
            <br></br>
          </center>
          <p>
            <ul>
              <li>1. Apa itu aplikasi Step Mate?</li>
              <li>
                Ini adalah aplikasi di mana Anda dapat mengikuti langkah-langkah
                Anda setiap hari dan mengonversi langkah-langkah tersebut
                menjadi SMP (Step Mate Points) dengan menonton iklan. Dengan SMP
                yang dihasilkan, Anda dapat menerima hadiah yang selalu
                diperbarui dari toko kami!
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>2. Apakah aplikasi Step Mate gratis?</li>
              <li>
                Ya, aplikasi ini sepenuhnya gratis untuk diunduh dan digunakan.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>3. Bagaimana cara membuat akun?</li>
              <li>
                Ketika Anda membuka aplikasi, Anda dapat masuk dengan akun
                Google Anda. Jika Anda memiliki kode undangan, masuklah dengan
                menambahkan kode ini, maka hadiah SMP (Bonus Undang & Dapatkan)
                akan ditentukan untuk Anda dan teman yang mengundang Anda segera
                setelah Anda mengonversi langkah pertama Anda.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>4. Apa itu SMP?</li>
              <li>
                SMP (Step Mate Points) adalah mata uang dalam aplikasi yang Anda
                dapatkan saat Anda mengonversi langkah-langkah harian dan
                melakukan pembelian di toko.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>5. Bagaimana cara mengumpulkan SMP?</li>
              <li>
                Anda dapat mengonversi langkah-langkah yang Anda ambil selama
                hari dengan menonton iklan. Semakin banyak Anda berjalan,
                semakin banyak Anda mendapatkan SMP! Tetapi harap diingat bahwa
                batas harian adalah 20.000 langkah yang dapat Anda konversi.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>6. Apa yang bisa saya lakukan dengan SMP?</li>
              <li>
                Anda dapat mendapatkan apa pun yang Anda pilih dari toko kami
                dengan SMP (Step Points). Jangan lupa untuk memeriksa toko
                secara teratur; itu selalu diperbarui!
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>7. Dapatkah saya mengonversi SMP menjadi uang tunai?</li>
              <li>
                SMP tidak dapat diubah menjadi uang tunai. Ini hanya digunakan
                untuk membeli hadiah dalam aplikasi.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>8. Berapa lama masa validitas SMP yang saya dapatkan? </li>
              <li>
                Waktu validasi adalah 1 tahun mulai dari saat Anda bergabung
                dengan Step Mate, untuk SMP Anda. Setiap ulang tahun
                keanggotaan, SMP yang tidak digunakan Anda akan dihapus dan
                diatur ulang.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>9. Dapatkah saya mentransfer SMP saya ke akun lain? </li>
              <li>
                SMP tidak dapat ditransfer ke akun lain. Semua poin berbasis
                akun.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                10. Bagaimana langkah-langkah saya berubah menjadi hadiah?{" "}
              </li>
              <li>
                Ketika Anda ingin mengonversi langkah-langkah Anda, Anda dapat
                menonton iklan dengan mengklik tombol "konversi langkah".
                Setelah iklan selesai, langkah Anda akan diubah menjadi SMP.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                11. Dapatkah saya mengonversi langkah-langkah saya kapan saja?{" "}
              </li>
              <li>
                Anda dapat mengonversi langkah-langkah Anda dengan menonton
                iklan kapan saja selama hari. Tetapi langkah-langkah harian Anda
                akan direset pukul 12:00 siang. Jadi, Anda memiliki waktu hingga
                pukul 11:59 malam.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>12. Apa yang harus dilakukan ketika Energi saya rendah? </li>
              <li>
                Ketika Anda berjalan, energi Anda semakin rendah. Dengan
                mengklik "ikon petir" di layar utama, Anda dapat meningkatkan
                energi Anda dan mendapatkan multiplier tertinggi untuk langkah
                Anda.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>13. Bagaimana cara saya menerima produk? </li>
              <li>
                Setelah mengonversi langkah-langkah Anda, Anda dapat memilih
                produk apa pun dari toko dengan SMP yang ditentukan di akun
                Anda.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>14. Bagaimana produk dikirim? </li>
              <li>
                Kami mengirim produk fisik melalui perusahaan kurir Blu Ex. Jika
                Anda memilih kode digital, Anda dapat menemukan detailnya di
                bagian "Hadiah" dalam aplikasi.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                15. Apakah saya membayar biaya pengiriman untuk produk yang saya
                pilih?{" "}
              </li>
              <li>
                Tidak. Step Mate menanggung biaya pengiriman semua hadiah yang
                Anda pilih. Satu-satunya pengecualian adalah; jika perusahaan
                kurir tidak dapat mengirimkan produk karena Anda tidak ada di
                alamat tersebut, produk itu akan kembali kepada kami. Setelah
                itu, Anda harus mengirim ulang produk dengan menekan tombol
                "Kirim Ulang" di bagian "Hadiah". Tetapi pada kali kedua ini,
                pembayaran akan ditanggung oleh Anda.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>16. Berapa lama saya harus menunggu agar produk dikirim? </li>
              <li>
                Persetujuan kode digital yang dibeli dilakukan dalam waktu 48
                jam. Produk reguler dikirim ke alamat yang Anda berikan dalam
                waktu 7 hari kerja. Anda dapat melacak ini melalui kemajuan dari
                bagian "Hadiah"
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                17. Apakah ada cara lain untuk mendapatkan lebih banyak SMP?{" "}
              </li>
              <li>
                Selain dari mengonversi langkah harian Anda dan menyelesaikan
                bonus, ketika teman-teman Anda masuk ke aplikasi dengan kode
                undangan Anda dan mengonversi langkah pertama mereka, Anda
                berdua akan mendapatkan hadiah.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>18. Bonus apa saja yang ada di Step Mate? </li>
              <li>
                Ada banyak bonus dalam aplikasi Step Mate. Beberapa di antaranya
                adalah; Bonus Reguler yang memerlukan 7 hari log masuk
                berturut-turut; Bonus Tonton & Menang harian, dan ada Bonus
                Langkah sebesar 1.000, 3.000, 5.000, 10.000, dan 20.000 yang
                dapat Anda peroleh SMP ketika Anda menyelesaikan langkah-langkah
                yang diperlukan.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>19. Apakah saya bisa mengundang teman-teman saya? </li>
              <li>
                Anda dapat menambahkan teman-teman Anda dengan mengklik tautan
                profil teman Anda atau dengan mencari teman Anda di tombol
                pencarian.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>20. Dapatkah saya mengundang teman-teman saya? </li>
              <li>
                Ya! Ketika teman Anda bergabung dengan Step Mate menggunakan
                kode undangan Anda dan mengonversi langkah pertama mereka, Anda
                berdua akan mendapatkan hadiah (Bonus Undang & Dapatkan).
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                21. Apakah aplikasi Step Mate menggunakan daya baterai tambahan?{" "}
              </li>
              <li>
                Step Mate tidak menggunakan daya baterai tambahan karena
                mengakses informasi langkah Anda dari aplikasi Apple Health atau
                Google Fit.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                22. Bisakah saya menggunakan aplikasi dengan smartwatch saya?{" "}
              </li>
              <li>
                Jika smartwatch Anda kompatibel dengan Apple Health atau Google
                Fit, Step Mate dapat mengakses langkah yang Anda ambil selama
                hari melalui aplikasi ini.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>23. Mengapa saya tidak bisa menonton iklan? </li>
              <li>
                Iklan dalam aplikasi ditampilkan oleh penyedia iklan. Mungkin
                ada masalah dengan menampilkan iklan saat pengguna banyak
                menonton iklan. Untuk menghindari masalah tersebut, kami
                sarankan Anda mengonversi langkah Anda di luar jam sibuk (akhir
                hari adalah waktu paling sibuk). Jika masalah ini berlangsung
                selama beberapa menit dan Anda tidak pernah bisa menonton iklan,
                kami sarankan Anda memastikan tidak menggunakan Proxy, VPN, atau
                Ad Blocker, dan pengaturan DNS Anda berada dalam mode DHCP di
                Android dan otomatis di perangkat IOS.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>24. Mengapa Poin Saya Tidak Dimuat? </li>
              <li>
                Anda mungkin belum memberikan izin yang diperlukan agar Step
                Mate dapat melihat data langkah. Periksa hal ini dengan
                mengikuti langkah-langkah di bawah ini. Untuk perangkat iOS,
                pergi ke Pengaturan{">"}Privasi{">"}Kesehatan{">"}Step Mate dari
                ponsel Anda. Anda perlu mengaktifkan semua izin di sini. Pada
                perangkat Android, pergi ke Pengaturan{">"}Aplikasi{">"}Step
                Mate{">"}Izin dari ponsel Anda. Anda perlu mengaktifkan semua
                izin di sini.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>
                25. Saya tidak bisa menonton iklan. Apa yang harus saya lakukan?{" "}
              </li>
              <li>
                Pertama-tama, perlu diingat bahwa kadang-kadang, mungkin
                memerlukan waktu bagi saluran iklan kami untuk memberikan iklan
                yang disesuaikan untuk Anda. Kadang-kadang, periode ini dapat
                diperpanjang karena permintaan tinggi. Jika Anda mengalami
                masalah ini secara konsisten, kami meminta hal berikut dari
                Anda:
                <br />- Hapus semua pemblokir iklan, VPN, atau proxy dari ponsel
                Anda,
                <br />- Pastikan internet yang Anda gunakan bukan dari jaringan
                nirkabel terbatas (seperti sekolah atau tempat kerja),
                <br />- Pastikan pengaturan DNS diatur ke DHCP pada ponsel
                Android dan otomatis pada ponsel iPhone,
                <br />- Pastikan aplikasi{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.webview"
                  style={{ color: "#3366FF" }}
                >
                  Android System WebView
                </a>{" "}
                dan
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.gms"
                  style={{ color: "#3366FF" }}
                >
                  {" "}
                  Google Play Services
                </a>{" "}
                Anda diperbarui.
                <br />- Setelah memperbarui semua pengaturan Anda dengan cara
                ini, coba lagi menggunakan jaringan seluler.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>26. Belum menemukan jawaban yang Anda cari? Hubungi Kami </li>
              <li>
                Jika Anda memiliki pertanyaan yang tidak dijawab dalam FAQ, Anda
                dapat menghubungi tim dukungan Step Mate dengan mengirim email
                ke contact@stepmate.app
              </li>
            </ul>
          </p>
          <br /> <br />
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default FaqInd;
